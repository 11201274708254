import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "iOSDC 2020 にシルバースポンサーとして協賛します",
  "date": "2020-09-14T09:11:00.000Z",
  "slug": "entry/2020/09/14/181100",
  "tags": ["medley"],
  "hero": "./2020_09_14.png",
  "heroAlt": "iosdc スポンサー"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200914/20200914173618.png",
      "alt": "20200914173618.png"
    }}></img>
    <p>{`みなさん、こんにちは。エンジニア・デザイナー採用担当の平木です。`}</p>
    <p>{`メドレーでは iOS 開発コミュニティに微力ながら還元したいと、2017 年より iOSDC に協賛をさせていただいておりますが、今年も`}<a parentName="p" {...{
        "href": "https://iosdc.jp/2020/sponsor.html"
      }}>{`シルバースポンサー`}</a>{`として協賛させていただくことになりました。`}</p>
    <p><em parentName="p">{`過去の参加レポート`}</em></p>
    <p><a parentName="p" {...{
        "href": "/entry/2018/09/13/175702"
      }}>{`entry/2018/09/13/175702`}</a></p>
    <p><a parentName="p" {...{
        "href": "/entry/2017/09/27/120000"
      }}>{`entry/2017/09/27/120000`}</a></p>
    <h1>{`iOSDC 初のオンライン開催`}</h1>
    <p>{`今年の iOSDC は初のオンライン開催ということで、例年とはまた違った楽しみ方ができそうです。`}</p>
    <p>{`公式スタッフブログに記載されていますが、今年はノベルティグッズが`}<a parentName="p" {...{
        "href": "https://blog.iosdc.jp/2020/08/21/novelty-items/"
      }}>{`配送`}</a>{`されるとのことで、既に届いている方もいらっしゃるようです。弊社のパンフレットも同封させていただいてます。`}</p>
    <p>{`今年の`}<a parentName="p" {...{
        "href": "https://fortee.jp/iosdc-japan-2020/timetable"
      }}>{`タイムテーブル`}</a>{`も大変興味深い内容が多く、今から視聴が楽しみですが(スポンサーチケットを頂いてはいますが、筆者も自腹でチケット購入しています)`}<a parentName="p" {...{
        "href": "https://blog.iosdc.jp/2020/09/07/how-to-enjoy/"
      }}>{`ニコニコ生放送での視聴`}</a>{`ということで、例年とは違い、コメントなどで盛り上がりがあるのではないかと、大変期待しています。`}</p>
    <p>{`Ask the Speaker では`}<a parentName="p" {...{
        "href": "https://discord.com/"
      }}>{`Discord`}</a>{`を使用するということで、対面とはまた違ったコミュニケーションが取れそうです。参考 URL や図などがすぐに出してもらえたりなど、さらに学びが深くなるのではないかと思っています。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`今回は直接会場でみなさんとの交流はできませんが、弊社のエンジニアも参加予定ですので、一緒に楽しんでいきたいと思います。後日イベントレポートもこちらで公開したいと考えています。`}</p>
    <p>{`また、メドレーではこうしたイベントにも興味をお持ちの iOS エンジニアを絶賛募集中です。お気軽に下記よりご連絡いただければ、カジュアルにお話をしたいです!`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      